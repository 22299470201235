<template>
  <div class="container bg-white">
    <div class="row">
      <div class="col-xs-18">
        <h3>{{ $t('privacy.cookies.title') }}</h3>
        <div class="divider"></div>
        <p>{{ $t('privacy.cookies.line1') }}</p>
        <p>{{ $t('privacy.cookies.line2') }}</p>
        <p>{{ $t('privacy.cookies.line3') }}</p>
        <p>{{ $t('privacy.cookies.line4') }}</p>
        <p>{{ $t('privacy.cookies.line5') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'cookies',
};
</script>
