<template>
  <div class="container bg-white">
    <div class="row">
      <div class="col-xs-18">
        <h3>{{ $t('privacy.informasiPengguna.title') }}</h3>
        <div class="divider"></div>
        <p>{{ $t('privacy.informasiPengguna.line1') }}</p>
        <p>{{ $t('privacy.informasiPengguna.line2') }}</p>
        <p>{{ $t('privacy.informasiPengguna.line3') }}</p>
        <p>{{ $t('privacy.informasiPengguna.line4') }}</p>
        <p>{{ $t('privacy.informasiPengguna.line5') }}</p>
        <p>{{ $t('privacy.informasiPengguna.line6') }}</p>
        <p>{{ $t('privacy.informasiPengguna.line7') }}</p>
        <p>{{ $t('privacy.informasiPengguna.line8') }}</p>
        <p>{{ $t('privacy.informasiPengguna.line9') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'informasi-pengguna',
};
</script>
